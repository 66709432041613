import React from 'react';
import FlexWrapper from '../FlexWrapper';
import ProgressBar from '../../components/Input/ProgressBar';
import { statuses } from './WorkflowDetails';
import StatusToken from '../../components/StatusTokens';
import PulseDot from '../../components/Icons/PulseDot';
import "./WorkflowInfoBlock.scss";

const WorkflowInfoBlock = ({ header, status, completedTasks, totalTasks, actionName, workflowSummary, onClick, active, clickable, isSelected }) => {
	const taskIsInProgress = active;
	return (
		<FlexWrapper className={`workflow-info-block-header ${isSelected ? "workflow-info-block-header-active" : ""} ${clickable ? "clickable-info-block" : ""} ${workflowSummary ? "not-disabled" : ""}`} direction="column" align='start' onClick={onClick} >
			<div className={`workflow-info-block-title ${taskIsInProgress ? "workflow-info-block-active" : ""} ${workflowSummary ? "not-disabled" : ""}`} >
				{header}
				{taskIsInProgress && <PulseDot />}
			</div>
			<FlexWrapper direction="row" className={`workflow-info-block-content`}>
				<FlexWrapper direction="column" align='start' className={`workflow-info-block-status-section`}>
					<p>Status</p>
					<div className={`workflow-info-block-detail`}>
						<InfoBlockStatusToken status={status} />
					</div>
				</FlexWrapper>
				<FlexWrapper direction="column" align='start' className={`workflow-info-block-progress-section`}>
					<p>{workflowSummary ? "Total Progress" : "Progress"}</p>
					<div className={`workflow-info-block-detail`}>
						<ProgressBar noToolTip extent={totalTasks} value={completedTasks} colour1={totalTasks === completedTasks ? "#E0FFF6" : null} colour2={totalTasks === completedTasks ? "#56D1C3" : null} />
					</div>
				</FlexWrapper>
				{!workflowSummary &&
					<FlexWrapper direction="column" align='start' className={`workflow-info-block-completion-section`}>
						<p>{actionName}</p>
						<div className={`workflow-info-block-detail`}>
							<p>{completedTasks}/{totalTasks}</p>
						</div>
					</FlexWrapper>
				}
			</FlexWrapper>
		</FlexWrapper>
	);
}

export const InfoBlockStatusToken = ({ status, style }) => {
	let statusType = '';
	let caseHandledStatus = statuses[status] ? statuses[status] : status;


	switch (caseHandledStatus) {
		case statuses.PENDING:
		case statuses.PENDING_FEEDBACK:
			statusType = 'warning';
			break;
		case statuses.IN_PROGRESS:
			statusType = '';
			break;
		case statuses.COMPLETED:
		case statuses.COMPLETE:
		case statuses.APPROVED:
			statusType = 'positive';
			break;
		case statuses.ERROR:
		case statuses.INCOMPLETE:
			statusType = 'danger';
			break;
		default:
			statusType = '';
	}

	return (
		<StatusToken label={caseHandledStatus ? caseHandledStatus : status ? status : "-"} type={statusType} style={style} />
	)
}

export default WorkflowInfoBlock;