import React, { useEffect, useState } from "react";
import { useStorage } from "../../../Utils/Utils";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getSubBrandName } from "../../AdminPage/BrandSettings/BrandsStyles"
import "./TopBannerExtension.scss";


const TopBannerExtension = (props) => {
    const { header, icon, links, headerOnly } = props

    const storage = useStorage()
    const history = useHistory()
    const locationHook = useLocation()
    const location = locationHook.pathname

    // TopBanner states
    const [subTab, setSubTab] = useState(null)
    const [alternativeName, setAlternativeName] = useState(null)
    // Analytics states
    const [publication, setPublication] = useState(null)
    const [tickers, setTickers] = useState([])
    const [group, setGroup] = useState(null);
    const [groupingName, setGroupingName] = useState(null)
    const [users, setUsers] = useState([])
    const [companies, setCompanies] = useState([])
    const [authors, setAuthors] = useState([])
    // CRM states
    const [contacts, setContacts] = useState([])
    const [organisations, setOrganisations] = useState([])
    const [lists, setLists] = useState([])
    // Library states
    const [doc, setDoc] = useState(null)
    // Workflow Monitor states
    const [workflows, setWorkflows] = useState([])

    const [me, setMe] = useState(null)
    const perms = me && me.perms ? me.perms : []

    useEffect(() => {
        if (header === "Analytics") {
            const pathArray = location.split("/")
            const researchId = (pathArray.length === 6 || pathArray.length === 7) ? +pathArray[pathArray.length - 1] : null

            if (researchId && !isNaN(researchId)) {
                const path = pathArray.includes("group") ?
                    `/analytics/group/${+pathArray[3]}/notes?from=2000-01-01&to=${format(new Date(), 'yyyy-MM-dd')}` : pathArray.includes("tickers") ?
                        `/analytics/ticker/${+pathArray[3]}/notes?from=2000-01-01&to=${format(new Date(), 'yyyy-MM-dd')}` :
                        `/analytics/users/${+pathArray[4]}`

                storage.getOrFetch(path).then((fetchedPublications) => {
                    if (fetchedPublications) {
                        const foundPublication = pathArray.includes("users") ?
                            fetchedPublications.events && fetchedPublications.events.find(n => n && n.note && n.note.publicationId === researchId) :
                            fetchedPublications.find(p => p.noteId === researchId)
                        if (foundPublication && pathArray.includes("users")) setPublication(foundPublication.note.title)
                        else if (!foundPublication && pathArray.includes("users")) setPublication("Unknown Title")
                        else if (foundPublication) setPublication(foundPublication.title)
                    }
                })
            } else setPublication(null)

            if (tickers.length === 0) storage.getOrFetch("/crm/tickers").then(setTickers)
            if (companies.length === 0) storage.getOrFetch("/analytics/companies").then(setCompanies)
            if (authors.length === 0) storage.getOrFetch("/analytics/aggregates/authors").then(setAuthors)
            if (users.length === 0) storage.getOrFetch("/analytics/users").then(setUsers)
        }

        if (header === "CRM") {
            if (perms.includes("USERS") && contacts.length === 0) storage.getOrFetch("/crm/contacts").then(setContacts)
            if (perms.includes("COMPANIES") && organisations.length === 0) storage.getOrFetch("/crm/organisations").then(setOrganisations)
            if (perms.includes("DISTRIBUTION") && lists.length === 0) storage.getOrFetch("/crm/lists").then(setLists)
        }

        if (header === "Workflow Monitor") {
            console.log("Fetching workflows")
            storage.getOrFetch("/workflows/list").then(setWorkflows)
        }

    }, [header, location, storage])


    useEffect(() => {
        storage.watch("/crm/contacts", setContacts)
        storage.watch("/crm/organisations", setOrganisations)
        storage.watch("/crm/lists", setLists)
        storage.getOrFetch("/crm/me").then(setMe)
        return () => {
            storage.unwatch("/crm/contacts")
            storage.unwatch("/crm/organisations")
            storage.unwatch("/crm/lists")
        }
    }, [])

    useEffect(() => {
        const pathname = location
        const pathArray = location.split("/")

        if (header === "Analytics") {
            const tickerOrGroupId = +pathArray[3]
            const userOrCompanyOrAuthorId = !isNaN(+pathArray[4]) ? +pathArray[4] : pathArray[4] && pathArray[4].includes("@") ? pathArray[4] : null

            if (tickerOrGroupId && !isNaN(tickerOrGroupId)) setAlternativeName(tickerOrGroupId)
            else if (userOrCompanyOrAuthorId !== null) setAlternativeName(userOrCompanyOrAuthorId)
            else setAlternativeName(null)

            if (pathname.includes("tickers") && subTab !== "Tickers") {
                if (subTab !== "Tickers") setSubTab("Tickers")
            } else if (pathname.includes("group")) {
                if (tickerOrGroupId) {
                    storage.getOrFetch("/crm/groupings/-1/" + tickerOrGroupId).then(g => {
                        setGroup(g);
                        setGroupingName(g.groupingDetails.groupName);
                    })
                }
                if (subTab !== "Groups") setSubTab("Groups")
            } else if (pathname.includes("users")) {
                if (subTab !== "Users") setSubTab("Users")
            } else if (pathname.includes("companies")) {
                if (subTab !== "Companies") setSubTab("Companies")
            } else if (pathname.includes("dashboard")) {
                if (subTab !== "Dashboard") setSubTab("Dashboard")
            } else if (pathname.includes("authors")) {
                if (subTab !== "Authors") setSubTab("Authors")
            }

        } else if (header === "CRM") {
            const pathId = +pathArray[4]
            if (!isNaN(pathId)) setAlternativeName(pathId)
            else setAlternativeName(null)

            if (pathname.includes("users")) {
                if (subTab !== "Users") setSubTab("Users")
            } else if (pathname.includes("companies")) {
                if (subTab !== "Companies") setSubTab("Companies")
            } else if (pathname.includes("distribution")) {
                if (subTab !== "Distribution Lists") setSubTab("Distribution Lists")
            } else if (pathname.includes("supressions")) {
                if (subTab !== "Suppressed Emails") setSubTab("Suppressed Emails")
            }

        } else if (header === "Library") {
            const pathId = +pathArray[3]
            if (!isNaN(pathId)) setAlternativeName(pathId)
            else setAlternativeName(null)

            if (pathname.includes("delivery")) {
                storage.getOrFetch(`/documents/document/${pathArray[3]}/details`).then((fetchedDoc) => {
                    setDoc(fetchedDoc)
                    setAlternativeName(pathId)
                    setSubTab("Library")
                }).catch(() => setSubTab("Library"), setAlternativeName(pathId))
            }

        } else if (header === 'Admin') {
            if (pathArray.length === 5) setAlternativeName(pathArray[4])
            if (pathname.includes('sub-brands')) {
                if (subTab !== "Sub-Brands") setSubTab("Sub-Brands")

            } else if (pathname.includes('brand-settings/brand')) {
                if (subTab !== "Brand") setSubTab("Brand")

            } else if (pathname.includes('fund-management')) {
                const string = (String(pathArray[3]).charAt(0).toUpperCase() + String(pathArray[3]).slice(1))
                if (subTab !== string) setSubTab(string)

                storage.getOrFetch(`/funds/${pathArray[3]}/${pathArray[4]}`).then((asset) => {
                    const assetName = asset.fundName ? asset.fundName : asset.offerName ? asset.offerName : asset.schemeName
                    setAlternativeName(assetName)
                }).catch(() => setAlternativeName(pathArray[4]))
            }
        } else if (header === 'Workflow Monitor') {
            const relevantWorkflow = workflows.find((flow) => flow.id == pathArray[2])
            const pathId = relevantWorkflow ? relevantWorkflow.processName : pathArray[2]
            if (!isNaN(pathId)) {
                setAlternativeName(pathId)
                setSubTab("Workflow Monitor")
            } else setAlternativeName(null)
            // TODO: Get the workflow name
        } else setAlternativeName(null)
    }, [location, publication])

    useEffect(() => {
        const locationArray = location.split('/')
        const selectedLink = location === "/" ? { name: "Library" } :
            location?.includes("group") ? links.find(tab => tab && tab.name === "Groups") :
                location.includes("suppressions") ? links.find(tab => tab && tab.name === "Suppressed Emails") :
                    location?.includes("sub-brands") ? links.find(tab => tab && tab.name === "Sub-Brands") :
                        locationArray[3] === 'schemes' ? links.find(tab => tab && tab.name === 'Schemes') :
                            locationArray[3] === 'offers' ? links.find(tab => tab && tab.name === 'Offers') :
                                locationArray[3] === 'funds' ? links.find(tab => tab && tab.name === 'Funds') :
                                    links.find(tab => tab && tab.name && location?.includes(tab.name.toLowerCase()))

        if (selectedLink && selectedLink.name) setSubTab(selectedLink.name)

    }, [links])


    function selectTab(link) {
        setSubTab(link.name)
        setAlternativeName(null)
        setPublication(null)
        history.push(link.path)
    }

    function getTickerDisplaying(link, id, header) {
        const pathArray = location.split("/")
        // Analytics
        if (tickers && link === "Tickers") {
            const ticker = tickers.find(t => t.id === id)
            if (ticker) return ticker.ric || ticker.name
            else return ""

        } else if (link === "Groups") {
            if (group) return groupingName + " — " + group.groupName
            else return ""

        } else if (users && link === "Users" && header === "Analytics") {
            const user = users.find(u => u.contact.id === id)
            if (user) return user.contact.firstName + " " + user.contact.lastName
            else return ""

        } else if (companies && link === "Companies" && header === "Analytics") {
            const company = companies.find(c => c.organisationId === id)
            if (company) return company.organisationName
            else return ""

        } else if (authors && link === "Authors" && header === "Analytics") {
            const author = authors.find(a => a.email === id)
            if (author) return author.name
            else return ""

            // CRM
        } else if (contacts && link === "Users" && header === "CRM") {
            const contact = contacts.find(c => c.id === id)
            if (contact) return contact.firstName + " " + contact.lastName
            else return ""

        } else if (organisations && link === "Companies" && header === "CRM") {
            const organisation = organisations.find(o => o.id === id)
            if (organisation) return organisation.name
            else return ""

        } else if (lists && link === "Distribution Lists") {
            const list = lists.find(l => l.listId === id)
            if (list) return list.name
            else return ""

            // Library
        } else if (header === "Library" && doc) {
            return (doc.summary && doc.summary.title) || (doc.portal && doc.portal.title) || (doc.distribution && doc.distribution.title) || ""

        } else if (header === 'Workflow Monitor') {
            const relevantWorkflow = workflows.find((flow) => flow.id == pathArray[2])
            const processName = relevantWorkflow ? relevantWorkflow.processName : pathArray[2]
            return processName

            // Admin/brand-settings
        } else if (header === "Admin") {

            if (link === 'Schemes' || link === 'Offers' || link === 'Funds') return id
            else if (link === "Sub-Brands") return getSubBrandName(id)

            // Workflow Monitor
        } else return ""
    }

    function isTheCorrectName(name) {
        return name === subTab
    }


    return (
        <div className={`top-banner-extension-outter${icon === null ? " with-left-extension" : ""}`}>
            <div className="top-banner-extension">
                <div className={`top-banner-ext-header ${headerOnly ? "no-tabs" : ""}`}>
                    {icon}
                    <h3 onClick={() => headerOnly ? selectTab(links[0]) : null} style={{ cursor: headerOnly ? "pointer" : "default" }}>{header}</h3>
                    {alternativeName && headerOnly ? <>
                        <h3 className={`top-banner-ext-link`}>/</h3>
                        <h3 className={`top-banner-ext-link selected`} style={{ cursor: "default" }}>{getTickerDisplaying(null, alternativeName, header)}</h3>
                    </> : ""}
                </div>
                {!headerOnly && <div className="top-banner-ext-links">
                    {links.map((link) => {
                        if (!link.requires || (me && me.perms.includes(link.requires))) {
                            return alternativeName && isTheCorrectName(link.name) ?
                                < div key={header + "-" + link.name} style={{ display: "flex", gap: "0.25rem" }}>
                                    <h3 className={`top-banner-ext-link`} onClick={() => selectTab(link)}>{link.name}</h3>
                                    <h3 className={`top-banner-ext-link`}>/</h3>
                                    <h3 className={`top-banner-ext-link ${!publication ? "selected" : ""}`} onClick={() => { history.push(link.name === "Library" ? null : link.name === "Users" ? link.path + "/user/" + alternativeName : link.path + "/" + alternativeName); setPublication(null) }}>
                                        {getTickerDisplaying(link.name, alternativeName, header)}
                                    </h3>
                                    {publication && <h3 className={`top-banner-ext-link`}>/</h3>}
                                    {publication && <h3 className={`top-banner-ext-link selected truncate`}>{publication}</h3>}
                                </div> : <h3 key={header + "-" + link.name} className={`top-banner-ext-link ${subTab === link.name ? "selected" : ""}`} onClick={() => selectTab(link)}>{link.name}</h3>
                        }
                        return ""
                    })}
                </div>}
            </div>
        </div >
    )
}

export default TopBannerExtension;