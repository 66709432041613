import React from 'react';
import { AlertCircle, Tool } from 'react-feather';
import Checkbox from '../../../components/Checkbox';
import ExpandableTableRow from '../../../components/Table/ExpandableTableRow';
import Table from '../../../components/Table/Table';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import PageGridItem from '../../Shared/Page/PageGridItem';
import ParamField from './ParamField'
import './TemplateMgmt.scss'


const TemplateSettingsList = ({ data, addActionToTemplate, selected, templateId, setSelectedPublishActions, removeAction, templateType, editMode, category }) => {
	const colWidths = [40 + "%", 55 + "%", "5%"];

	const camelToTitle = (camelCaseText) => {
		return camelCaseText
			.replace(/([A-Z])/g, ' $1') // Add a space before each uppercase letter
			.replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
	};

	const showParams = (action) => {
		if (!action) return null;

		return (
			<div className='param-wrapper'>
				{action.params.map((param, idx) => <ParamField key={idx} param={param} action={action} template={templateId} setSelectedPublishActions={setSelectedPublishActions} />)}
			</div>
		)
	}

	const dataMap = () => data && data.map((action, index) => {
		const params = selected && selected.find((sel) => sel.stepId === action.id);
		const isActive = params && params.active
		const disabled = !action.implementation
		const selectedInstance = isActive ? selected.find(a => a.stepId === action.id) : null;

		let className = `cursor-pointer row-border-top`;
		let invalid = false;

		// if (!isActive) {
		// 	return <tr key={action.id} className={`row-border-top ${disabled ? 'disabled-row' : ''}`}>
		// 		<td><Checkbox checked={isActive} disabled={disabled || !editMode} label={action.displayName} onChange={() => addActionToTemplate(action)} /></td>
		// 		<td>{action.description}</td>
		// 		<td></td>
		// 	</tr>
		// }

		if (selectedInstance !== null) {
			for (let param of selectedInstance.params) {
				if (param.required) {
					const val = selectedInstance.paramValues[param.name];
					if (!val || (val && val.length === 0)) {
						invalid = true;
						break;
					}
				}
			}
		}

		return (
			<ExpandableTableRow key={action.id} className={className} expandContent={showParams(selectedInstance)} dontExpand={disabled} >
				<td><Checkbox checked={isActive} disabled={!editMode} label={action.displayName} onChange={(e) => {!isActive ? addActionToTemplate(action) : removeAction(action)}} /></td>
				<td>{action.description} </td>
				<td>{invalid ? <AlertCircle /> : null}</td>
			</ExpandableTableRow>
		);
	})

	return (
		<>
			<PageGridItem col="1 / span 6">
				<DetailSectionHeader header={`${camelToTitle(category)} Actions`} icon={<Tool />} className='mb-m' />
				<Table colWidths={colWidths} dataMap={dataMap()} />
			</PageGridItem>
		</>
	);
}

export default TemplateSettingsList;