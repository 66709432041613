import React from 'react';
import "./Icons.scss";

const ActiveWorkFlowIcon = ({ size, className, style, colour }) => {

	//width 400
	return (
		<svg style={style ? style : {}} className={`${className ? className : ""} material-downloaded-icon`} xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 -960 960 960" width="32px" fill={colour ? colour : "#999DAD"}>
			<path d="M687.33-80q-79.95 0-136.31-56.35-56.35-56.36-56.35-136.32 0-79.95 56.35-136.31 56.36-56.35 136.31-56.35 79.96 0 136.32 56.35Q880-352.62 880-272.67q0 79.96-56.35 136.32Q767.29-80 687.33-80Zm61.17-93.67 27.83-28-75-75v-112H662V-262l86.5 88.33ZM186.67-120q-27.5 0-47.09-19.58Q120-159.17 120-186.67v-586.66q0-28.34 19.17-47.5Q158.33-840 186.67-840H377q8.33-35 37.33-57.5T480-920q37.33 0 66.17 22.5Q575-875 583.33-840h190q28.34 0 47.5 19.17Q840-801.67 840-773.33v288.66q-16-10.33-32.34-17.65-16.35-7.32-34.33-13.01v-258h-66.66v100H253.33v-100h-66.66v586.66H444q5.67 17.34 13.33 33.67Q465-136.67 476-120H186.67ZM480-773.33q17 0 28.5-11.5t11.5-28.5q0-17-11.5-28.5t-28.5-11.5q-17 0-28.5 11.5t-11.5 28.5q0 17 11.5 28.5t28.5 11.5Z" />
		</svg>
	);
}

export default ActiveWorkFlowIcon;
