import React, { useEffect, useState } from 'react'
import SidebarExtensionWrapper from '../components/SideBar/SideBarExtension/SideBarExtensionWrapper';
import SideBarLeft from '../components/SideBar/SideBarLeft';
import TopBar from '../components/TopBar/TopBar';
import { withRouter, useLocation } from "react-router";
import TopBannerExtension from './Shared/Banner/TopBannerExtension'
import { BarChart2, User, BookOpen, Settings } from 'react-feather';
import { withStorage } from '../Utils/Utils';
import ActiveWorkFlowIcon from '../images/icons/ActiveWorkflowIcon'
import "./MenuWrapper.scss";
// import SideBarRight from '../components/SideBar/SideBarRight';
// import CommentsSidebarExtension from '../components/SideBar/SideBarExtension/CommentsSidebarExtension';


const MenuWrapper = (props) => {
	let [sideBarExtensionLeft, setSideBarExtensionLeft] = useState(true)
	let [sidebarExtLeftHeader, setSidebarExtLeftHeader] = useState({})
	let [sideBarRight, setSideBarRight] = useState(false)
	let [sideBarExtensionRight, setSideBarExtensionRight] = useState("")
	let [alertBannerActive, setAlertBannerActive] = useState(false)
	let [sidebarExtLeftLinks, setSidebarExtLeftLinks] = useState([])
	let [topBarExtension, setTopBarExtension] = useState(false)
	let [topBarExtContent, setTopBarExtContent] = useState({})

	const [topMessage, setTopMessage] = useState(null);

	const location = useLocation()
	let pathArray = location.pathname.split("/")

	const { topComponent, user, noPermissions, organisation } = props;

	useEffect(() => {
		setTopMessage(topComponent);
	}, [topComponent])

	useEffect(() => {
		props.storage.watch("research-master-grid", updateGridClasses)

		return (
			props.storage.unwatch("research-master-grid")
		)
	}, [])

	const updateGridClasses = (gridClass) => {
		switch (gridClass) {
			case "sideBarExtLeftActive":
				setSideBarExtensionLeft(true)
				break;
			case "sideBarExtLeftInactive":
				setSideBarExtensionLeft(false)
				break;
			case "SideBarRight":
				setSideBarRight(!sideBarRight)
				break;
			case "alertBannerActive":
				setAlertBannerActive(true)
				break;
			case "alertBannerInactive":
				setAlertBannerActive(false)
				break;
			case "sideBarExtRightActive":
				setSideBarExtensionRight('s')
				break;
			case "right-m":
				setSideBarExtensionRight('m')
				break;
			case "right-l":
				setSideBarExtensionRight('l')
				break;
			case "sideBarExtRightInactive":
				setSideBarExtensionRight('')
				break;
			case "topBarExtActive":
				setTopBarExtension(true)
				break;
			case "topBarExtInactive":
				setTopBarExtension(false)
				break;
			default:
				break;
		}
	}

	const removeSidebars = () => {
		setSidebarExtLeftLinks([])
		setSideBarExtensionLeft(false)
		setSideBarRight(false)
		setSideBarExtensionRight('')
		setAlertBannerActive(false)
		setTopMessage(null);
	}

	useEffect(() => {
		const path = props.location.pathname

		if (noPermissions) {
			setTopBarExtContent(null)
			setSidebarExtLeftLinks([])
			setSidebarExtLeftHeader(null)
			setTopBarExtension(false)
			setSideBarExtensionLeft(false)
			setSideBarRight(false)
			setSideBarExtensionRight('')
			setAlertBannerActive(false)
			setTopMessage(null);
		} else if (path === "/" || path.includes("delivery")) {
			setTopBarExtContent({
				header: "Library",
				icon: <BookOpen color="#0E8EFD" />,
				links: [{ name: "Library", path: "/" }],
				headerOnly: true
			})
			setSidebarExtLeftLinks([])
			setSidebarExtLeftHeader(null)
			setTopBarExtension(true)
			setSideBarExtensionLeft(false)
			setSideBarRight(false)
			setSideBarExtensionRight('')
			setAlertBannerActive(false)
			setTopMessage(null);
		}
		else if (path.includes("/editor")) {
			setTopBarExtContent(null)
			setSidebarExtLeftLinks([])
			setTopBarExtension(false)
			setSideBarExtensionLeft(true)
			setSideBarRight(true)
			setSideBarExtensionRight('')
			setAlertBannerActive(false)
		}
		else if (path.includes("/admin")) {
			if (user && user.perms && user.perms.some((val) => ["TICKERS", "MODEL_PORTFOLIOS", "SECTORS", "DISCLAIMER", "USER_MANAGEMENT", "COMPANY_MANAGEMENT", "ROLES", "TEMPLATES"].includes(val))) {
				setSidebarExtLeftHeader({ header: "Admin", icon: <Settings color="#0E8EFD" />, border: "bottom" })
				if (organisation?.hasSubBrands && path.includes("brand-settings")) {
					setTopBarExtension(true)
					setTopBarExtContent({
						header: 'Admin',
						icon: null,
						links: [
							{ name: "Brand", path: "/admin/brand-settings/brand" },
							{ name: "Sub-Brands", path: "/admin/brand-settings/sub-brands" }
						]
					})
					
				} else if (path.includes('fund-management')) {
					setTopBarExtension(true)
					setTopBarExtContent({
						header: 'Admin',
						icon: null,
						links: [
							{ name: "Schemes", path: "/admin/fund-management/schemes" },
							{ name: "Offers", path: "/admin/fund-management/offers" },
							{ name: "Funds", path: "/admin/fund-management/funds" }
						]
					})
				} else {
					setTopBarExtContent(null)
					setTopBarExtension(false)
				}
				setSidebarExtLeftLinks([
					{ name: "Fund Management", path: "/admin/fund-management", requires: "FUNDS" },
					{ name: "Data Manager", path: "/admin/data", requires: "DATA_MANAGEMENT" },
					{ name: "Tickers", path: "/admin/tickers", requires: "TICKERS" },
					{ name: "Model Portfolios", path: "/admin/model-portfolio", requires: "MODEL_PORTFOLIOS" },
					{ name: "Sectors", path: "/admin/sectors", requires: "SECTORS" },
					{ name: "Disclaimer", path: "/admin/disclaimers", requires: "DISCLAIMER" },
					{ name: "User Management", path: "/admin/user-management", requires: "USER_MANAGEMENT" },
					{ name: "Company Management", path: "/admin/company-management", requires: "COMPANY_MANAGEMENT" },
					{ name: "Roles and Permissions", path: "/admin/role-permissions", requires: "ROLES" },
					{ name: "Templates", path: "/admin/templates", requires: "TEMPLATES" },
					{ name: "Brand Settings", path: "/admin/brand-settings/brand", requires: "BRAND_MANAGEMENT" }
				])
				setSideBarExtensionLeft(true)
				setSideBarRight(false)
				setSideBarExtensionRight('')
				setAlertBannerActive(false)
				setTopMessage(null);
			} else {
				removeSidebars();
			}
		}
		else if (path.includes("/analytics")) {
			setSideBarExtensionLeft(true)
			setTopBarExtContent({
				header: "Analytics",
				icon: <BarChart2 color="#0E8EFD" />,
				links: [
					{ name: "Dashboard", path: "/analytics/dashboard" },
					{ name: "Tickers", path: "/analytics/tickers" },
					{ name: "Groups", path: "/analytics/group" },
					{ name: "Users", path: "/analytics/users" },
					{ name: "Authors", path: "/analytics/authors" },
					{ name: "Companies", path: "/analytics/companies" }]
			})
			setSidebarExtLeftLinks([])
			setSidebarExtLeftHeader(null)
			setTopBarExtension(true)
			setSideBarExtensionLeft(false)
			setSideBarRight(false)
			setSideBarExtensionRight('')
			setAlertBannerActive(false)
			setTopMessage(null);
		}
		else if (path.includes("/crm") && !path.includes("delivery")) {
			setTopBarExtContent({
				header: "CRM",
				icon: <User color="#0E8EFD" />,
				links: [{ name: "Users", path: "/crm/users" }, { name: "Companies", path: "/crm/companies" }, { name: "Distribution Lists", path: "/crm/distribution" }, { name: "Suppressed Emails", path: "/crm/suppressions" }]
			})
			setSidebarExtLeftLinks([])
			setSidebarExtLeftHeader(null)
			setTopBarExtension(true)
			setSideBarExtensionLeft(false)
			setSideBarRight(false)
			setSideBarExtensionRight('')
			setAlertBannerActive(false)
			setTopMessage(null);
		}
		else if (path.includes("/crm")) {
			if (user && user.perms && user.perms.some((val) => ["USERS", "COMPANIES", "DISTRIBUTION", "SUPPRESSED_EMAILS"].includes(val))) {
				setSidebarExtLeftLinks([{ name: "Users", path: "/crm/users", requires: "USERS" },
				{ name: "Companies", path: "/crm/companies", requires: "COMPANIES" },
				{ name: "Distribution Lists", path: "/crm/distribution", requires: "DISTRIBUTION" },
				{ name: "Suppressed Emails", path: "/crm/suppressions", requires: "SUPPRESSED_EMAILS" }])
				setSideBarExtensionLeft(true)
				setSideBarRight(false)
				setSideBarExtensionRight('')
				setAlertBannerActive(false)
				setTopMessage(null);
			} else {
				removeSidebars();
			}
		}
		else if (path.includes("/settings") && !path.includes("/publication")) {
			setTopBarExtContent(null)
			// setSidebarExtLeftLinks([{ name: "Profile", path: "/settings/profile" }, { name: "Settings", path: "/settings/general" }])
			setSidebarExtLeftLinks([])
			setSidebarExtLeftHeader(null)
			setTopBarExtension(false)
			setSideBarExtensionLeft(false)
			setSideBarRight(false)
			setSideBarExtensionRight('')
			setAlertBannerActive(false)
			setTopMessage(null);
		}
		else if (path.includes("/publication")) {
			setTopBarExtContent(null)
			setSidebarExtLeftLinks([{ name: "Note View", path: `/publication/${pathArray[2]}` }, { name: "Note Settings", path: `/publication/${pathArray[2]}/settings` }])
			setSidebarExtLeftHeader(null)
			setTopBarExtension(false)
			setSideBarExtensionLeft(true)
			setSideBarRight(false)
			setSideBarExtensionRight('')
			setAlertBannerActive(false)
			setTopMessage(null);
		}
		else if (path.includes("/active-workflows")) {
			setTopBarExtContent({
				header: "Workflow Monitor",
				icon: <ActiveWorkFlowIcon colour="#0E8EFD" />,
				links: [{ name: 'Workflow Monitor', path: '/active-workflows' }],
				headerOnly: true
			})
			setSidebarExtLeftLinks([])
			setSidebarExtLeftHeader(null)
			setTopBarExtension(true)
			setSideBarExtensionLeft(false)
			setSideBarRight(false)
			setSideBarExtensionRight(false)
			setAlertBannerActive(false)
			setTopMessage(null);
		}
		else {
			setTopBarExtContent(null)
			setSidebarExtLeftLinks([])
			setSidebarExtLeftHeader(null)
			setTopBarExtension(false)
			setSideBarExtensionLeft(false)
			setSideBarRight(false)
			setSideBarExtensionRight('')
			setAlertBannerActive(false)
			setTopMessage(null);
		}
	}, [props.location.pathname, user])

	return (
		<div id="master-grid" className={`${sideBarExtensionLeft ? " grid-sidebar-ext-left-active " : ""}${sideBarRight ? " grid-sidebar-right-active " : ""}${sideBarExtensionRight.length > 0 ? " grid-sidebar-ext-right-active" : ""}${sideBarExtensionRight === 'm' ? ' right-m' : sideBarExtensionRight === 'l' ? ' right-l' : ""}${alertBannerActive ? " grid-alert-banner-active " : ""}${topBarExtension ? " grid-topbar-ext-active " : ""}`}>
			<TopBar topMessage={topMessage} />
			{!noPermissions && topBarExtContent && topBarExtContent.links && topBarExtContent.links.length > 0 && <TopBannerExtension header={topBarExtContent.header} icon={topBarExtContent.icon} links={topBarExtContent.links} headerOnly={topBarExtContent.headerOnly} location={location.pathname} />}
			<SideBarLeft header={sidebarExtLeftHeader} />
			{sidebarExtLeftLinks.length > 0 && <SidebarExtensionWrapper links={sidebarExtLeftLinks} header={sidebarExtLeftHeader} />}
			{props.children}
		</div>
	)
};

export default withStorage(withRouter(MenuWrapper));