import React, { useEffect, useState, useRef } from "react";
import Modal from "./Modal";
import FlexWrapper from "../../layouts/FlexWrapper";
import Divider from "../Divider";
import PDFViewer from "../PDFViewer";
import ErrorBoundary from "../ErrorBoundary";
import CommentsSection from "../Comments/CommentsSection";
import IconLink from "../Menus/IconLink";
import { MessageSquare, ChevronLeft, ChevronRight, ChevronsUp, ChevronsDown, ChevronUp, ChevronDown } from 'react-feather';
import "./DraftNotePreviewModal.scss";
import { handleComplianceRead, handlePeerRead, handleSupervisorRead, useStorage } from "../../Utils/Utils";


const DraftNotePreviewModal = ({ handleClose, data, currentIndex, allRequests, setRowActivePreview, compliance, actions, autoOpenComments }) => {

	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(null);
	const [twoPageView, setTwoPageView] = useState(false);
	const [commentsOpen, setCommentsopen] = useState(false);
	const [blankPageMesurements, setBlankPageMesurements] = useState({ width: 0, height: 0 })
	const [me, setMe] = useState({});
	const previewContentRef = useRef()
	const storage = useStorage()


	useEffect(() => {
		sessionStorage.getItem("3SKYE.draft.modal.2PageView") === "true" ? setTwoPageView(true) : setTwoPageView(false)
		document.addEventListener("keydown", handleKeyDown);
		storage.getOrFetch(`/crm/me`).then((res) => {
			setMe(res)

			if (res.perms) {
				if (res.perms.includes("REVIEW_PEER")) {
					handlePeerRead(data.template, data.ticker)
				} else if (res.perms.includes("REVIEW_COMPLIANCE")) {
					handleComplianceRead(data.template, data.ticker)
				} else if (res.perms.includes("REVIEW_SUPERVISOR")) {
					handleSupervisorRead(data.template, data.ticker)
				}
			}
		})

		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	useEffect(() => {
		if (autoOpenComments) setCommentsopen(true)
	}, [autoOpenComments])

	const handleSetTwoPageView = (value) => {
		setTwoPageView(value);
		sessionStorage.setItem("3SKYE.draft.modal.2PageView", value);
	};

	const handleKeyDown = (e) => {
		if (e.key === "Escape") {
			handleClose();
		}
	};

	const handleOverlayClick = (e) => {
		// Check if the clicked element or any of its ancestors have the classes 'floating-sidebar' or 'preview-wrapper'
		if (!hasAncestorWithClass(e.target, "floating-sidebar") && !hasAncestorWithClass(e.target, "preview-wrapper")) {
			// Trigger the handleClose function if the clicked element or its ancestors don't have the specified classes
			handleClose();
		}
	};

	// Helper function to check if the element or any of its ancestors have a specific class
	const hasAncestorWithClass = (element, className) => {
		let currentElement = element;
		while (currentElement) {
			if (currentElement.classList.contains(className)) {
				return true;
			}
			currentElement = currentElement.parentElement;
		}
		return false;
	};

	const errorFallback = (
		<div style={{
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			paddingTop: "4rem"
		}}
		>
			<div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
				<p>An error occurred while loading this note. </p>
				<a
					href="mailto:kathryn.warusevitane@craigsip.com?subject=Investor Portal - Trouble Logging In"
					target="_blank"
					className="text-link colour-text-primary"
				>
					Contact Us
				</a>
			</div>
		</div>
	)

	const gotToPreviousNote = () => {
		if (currentIndex === 0) setRowActivePreview(allRequests.length - 1)
		else setRowActivePreview(currentIndex - 1)
	}

	const gotToNextNote = () => {
		if (currentIndex === allRequests.length - 1) setRowActivePreview(0)
		else setRowActivePreview(currentIndex + 1)
	}

	function calculatePage(page, up, doble, extreme) {
		if (extreme) {
			if (doble) {
				if (up) return totalPages%2 === 0 ? totalPages - 1 : totalPages
				else return 1
			} else {
				if (up) return totalPages
				else return 1
			}
		} else {
			if (doble) {
				if (up) return totalPages%2 === 0 && page === totalPages - 1 ? totalPages - 1 : totalPages%2 !== 0 && page === totalPages ? totalPages : page + 2
				else return page === 1 ? 1 : page - 2
			} else {
				if (up) return page === totalPages ? totalPages : page + 1
				else return page === 1 ? 1 : page - 1
			}
		}
	}

	return (
		<Modal handleClose={handleClose} className="draft-note-preview-modal" overlayClick={handleOverlayClick}>
			<div className={`preview-wrapper ${commentsOpen ? "right-sidebar-open" : ""}`}>
				{/* Header */}
				<FlexWrapper gap="s" align="center" direction='row' justify='space-between' className="mt-m pl-xl pr-xl preview-note-modal-header">
					<FlexWrapper gap="s" align="center" direction='row' justify='center' className="mt-m mb-m " onClick={gotToPreviousNote} style={{ cursor: "pointer", marginLeft: `calc(130px - 2rem` }}>
						<ChevronLeft color="#0D8EFD" />
						<p className="">Previous Note</p>
					</FlexWrapper>
					<FlexWrapper gap="s" align="center" direction='column' justify='center' className="mt-m mb-m">
						<h4>{data?.type}</h4>
						<h3>{data?.title ? data.title : data?.ticker ? data.ticker : ""}</h3>
					</FlexWrapper>
					<FlexWrapper gap="s" align="center" direction='row' justify='center' className="mt-m mb-m" onClick={gotToNextNote} style={{ cursor: "pointer", marginRight: commentsOpen ? `calc(474px - 1rem` : `calc(130px - 2rem` }}>
						<p>Next Note</p>
						<ChevronRight color="#0D8EFD" />
					</FlexWrapper>
				</FlexWrapper>

				<Divider spacing="bottom-l" />

				<FlexWrapper gap="s" align="flex-start" direction="row">

					{/* Left Sidebar */}
					<FlexWrapper className="floating-sidebar navigation-menu" direction="column" align="center" gap="s" style={{ minWidth: "130px" }}>
						<p>Page</p>
						<IconLink
							icon={<ChevronsUp color="#0D8EFD" />}
							onClick={() => setPage(calculatePage(page, false, twoPageView, true))}
							active={false}
							label="First Page"
						/>
						<IconLink
							icon={<ChevronUp color="#0D8EFD" />}
							onClick={() => setPage(calculatePage(page, false, twoPageView, false))}
							active={false}
							label="Previous Page"
						/>

						<div className="page-guide">
							<p>{twoPageView ? page === totalPages ? page : `${page} - ${page + 1}` : `${page} / ${totalPages || "1"}`}</p>
						</div>
						<IconLink
							icon={<ChevronDown color="#0D8EFD" />}
							onClick={() => setPage(calculatePage(page, true, twoPageView, false))}
							active={false}
							label="Next Page"
						/>

						<IconLink
							icon={<ChevronsDown color="#0D8EFD" />}
							onClick={() => setPage(calculatePage(page, true, twoPageView, true))}
							active={false}
							label="Last Page"
						/>

						<Divider width="60px" />
						<IconLink
							icon={
								<span className="material-symbols-outlined">
									description
								</span>
							}
							onClick={() => handleSetTwoPageView(false)}
							active={!twoPageView}
							label="Single Page View"
						/>

						<IconLink
							icon={
								<span className="material-symbols-outlined">
									two_pager
								</span>
							}
							onClick={() => handleSetTwoPageView(true)}
							active={twoPageView}
							label="Double Page View"
						/>

					</FlexWrapper>

					{/* Preview */}
					<FlexWrapper className="preview-wrapper-inner" direction="column" align="flex-start" gap="m" fullWidth ref={previewContentRef}>
						<ErrorBoundary fallback={errorFallback}>
							{data && <FlexWrapper direction="row" width="100%">

								<PDFViewer
									enableKeyboardNavigation
									ticker={data?.ticker}
									template={data?.template ? data.template : data?.type}
									id={data?.docId}
									scale={2}
									fullHeight
									fullWidth
									setTotalPages={setTotalPages}
									pageOverride={page}
									blankPageMesurements={blankPageMesurements}
									setBlankPageMesurements={setBlankPageMesurements}
								/>
								{twoPageView &&
									<PDFViewer
										enableKeyboardNavigation
										ticker={data?.ticker}
										template={data?.template ? data.template : data?.type}
										id={data?.docId}
										scale={1}
										fullHeight
										fullWidth
										setTotalPages={setTotalPages}
										pageOverride={page + 1}
										blankPage={page + 1 > totalPages}
										blankPageMesurements={blankPageMesurements}
										setBlankPageMesurements={setBlankPageMesurements}
									/>
								}
							</FlexWrapper>
								|| null}
						</ErrorBoundary>
					</FlexWrapper>

					{/* Right Sidebar */}
					<FlexWrapper className="floating-sidebar navigation-menu smaller" direction="column" align="center" gap="m" style={{ minWidth: "130px" }}>
						<IconLink
							icon={<MessageSquare />}
							onClick={() => null/*setCommentsopen(!commentsOpen)*/}
							active={commentsOpen}
							disabled
							// label="Comments"
							// counter={2}   // TODO: replace with real new messages counter
							counterClassName="workplace-template-preview-icon-counter"
							className="workplace-template-preview-icon disabled"
						/>
						<Divider spacing="none" width="60px" />
						{actions.map((action, idx) => (
							<IconLink
								key={idx}
								icon={action.icon}
								variant={action.variant}
								onClick={action.action}
								disabled={action.disabled}
								label={action.label}
								className="workplace-template-preview-icon"
							/>
						))}
					</FlexWrapper>

					{/* Comments */}
					{commentsOpen && <Divider vertical height={previewContentRef && previewContentRef.current ? `calc(${previewContentRef.current.clientHeight}px + 1.5rem` : "100vh"} marginTop="-1.5rem" />}
					{commentsOpen &&
						<FlexWrapper className="floating-sidebar" direction="column" align="flex-start" gap="m" style={{ minWidth: "344px" }}>
							<CommentsSection ticker={data && data.ticker} templateName={data && data.template} compliance={compliance} />
						</FlexWrapper>
					}
				</FlexWrapper>
			</div>
		</Modal>
	);
};

export default DraftNotePreviewModal;
