import React, { useEffect, useState } from 'react';
import FlexWrapper from '../FlexWrapper';
import Table from '../../components/Table/Table';
import ProgressBar from '../../components/Input/ProgressBar';
import MailIconFilled from '../../images/icons/MailIconFilled';
import { CheckCircle, ChevronDown, ChevronLeft, ChevronRight, ChevronUp, User } from 'react-feather';
import { format, parseISO } from 'date-fns';
import ProfileImage from '../../components/ProfileImage';
import "./WorkflowComplianceActions.scss";
import UserListModal from './UserListModal';
import { InfoBlockStatusToken } from './WorkflowInfoBlock';
import { useRemote } from '../../Utils/Utils';
import LoadingIcon from '../../components/LoadingIcon';
import Tooltip from '../../components/popups/Tooltip';

const WorkflowComplianceActions = ({ workflowId, taskId, isComplete }) => {
    const [data, setData] = useState(null)
    const [selectedRow, setSelectedRow] = useState(null)
    const [approversList, setApproversList] = useState([])
    const [selectedFunds, setSelectedFunds] = useState([])
    const [loading, setLoading] = useState(true)

    const displayedData = selectedRow ? [selectedRow] : data ? data : null

    const remote = useRemote()

    useEffect(() => {
        remote.get(`workflows/workflow/${workflowId}/workflowTask/${taskId}`).then((res) => {
            setData(res.data);
            setLoading(false)
        })
    }, [])

    const onFundClick = (idx) => {
        if (selectedFunds.includes(idx)) {
            setSelectedFunds(selectedFunds.filter(i => i !== idx))
        } else {
            setSelectedFunds([...selectedFunds, idx])
        }
    }

    const closeSelectedRow = () => {
        setSelectedRow(null)
        setSelectedFunds([])
    }

    const stepHeaders = ["Action", "Approvers Req", "Approvers Available", "Funds Approved", "Progress", ""]
    const stepWidths = [{ minWidth: "200px" }, { minWidth: "100px" }, { minWidth: "250px" }, { minWidth: "100px" }, { minWidth: "200px" }, { width: "50px" }]

    const stepDataMap = displayedData && displayedData.map((step, idx) => {
        const totalFunds = step.totalData
        const completedFunds = step.datas ? step.datas.filter(f => f.status === "Complete").length : 0
        const content = [
            step.name,
            <FlexWrapper >
                {Array.from({ length: step.approversNeeded }).map((_, index) => (
                    <User key={index} color="#0E8EFD" />
                ))}
            </FlexWrapper>,
            <FlexWrapper >
                {step.approversAvailable.map((approver, idx) => {
                    if (idx > 3) return null
                    return <ProfileImage onClick={(e) => { e.stopPropagation(); setApproversList(step.approversAvailable) }} style={idx > 0 ? { marginLeft: "-0.25rem", border: "white 1px solid", cursor: "pointer" } : {}} user={approver} />
                })}
                {step.approversAvailable.length > 4 && <p onClick={(e) => { e.stopPropagation(); setApproversList(step.approversAvailable) }} className='extension-label ml-s pointer'>{`+${step.approversAvailable.length - 4} more`}</p>}
            </FlexWrapper>,
            `${completedFunds}/${totalFunds}`,
            <ProgressBar extent={totalFunds} value={completedFunds} colour1={totalFunds === completedFunds ? "#E0FFF6" : null} colour2={totalFunds === completedFunds ? "#56D1C3" : null} />,
            !selectedRow || step.name !== selectedRow.name ? <ChevronRight color="#C2C4CE" /> : ""
        ]


        return <tr className='workflow-compliance-step-row pointer' onClick={() => {!selectedRow || step.name !== selectedRow.name ? setSelectedRow(step) : closeSelectedRow() }} >
            <td><FlexWrapper align="center" >{selectedRow && <ChevronLeft color="#0E8EFD" className='mr-m pointer' onClick={() => closeSelectedRow()} />}{content[0]}</FlexWrapper></td>
            <td>{content[1]}</td>
            <td>{content[2]}</td>
            <td>{content[3]}</td>
            <td>{content[4]}</td>
            <td className='workflow-cell-align-right'>{content[5]}</td>
        </tr>
    })

    const fundDataMap = selectedRow && selectedRow.datas.map((fund, idx) => {
        const numberOfApprovals = fund.responderActions ? fund.responderActions.filter(a => a.status === "Approved").length : 0
        const isLastInList = idx === selectedRow.datas.length - 1 
        const hasResponses = fund.responderActions && fund.responderActions.length > 0 
        const content = [
            fund.name,
            <FlexWrapper >
                {Array.from({ length: selectedRow.approversNeeded }).map((_, index) => {
                    return <User key={"approvers-needed-" + index} color={index < numberOfApprovals ? "#56D1C3" : "#D6D8DE"} />
                })}
            </FlexWrapper>,
            <InfoBlockStatusToken label={fund.status} status={fund.status} />,
            <Tooltip label={fund.requestEmailSentAt ? `Email Sent ${format(parseISO(fund.requestEmailSentAt), "hh:mmaa dd/MM/yyyy")}` : `Email Not Sent`} >
                <MailIconFilled colour={fund.requestEmailSentAt ? "#56D1C3": "#C2C4CE"} />
            </Tooltip>,
            selectedFunds && selectedFunds.includes(idx) ? <ChevronUp color={hasResponses ? "#0E8EFD" : "transparent"} /> :  <ChevronDown color={hasResponses ? "#C2C4CE" : "transparent"} />
        ]
        return (
            <>
                <tr key={"fund-" + idx} className={`workflow-compliance-step-row ${selectedFunds && selectedFunds.includes(idx) ? "fund-row-expanded" : ""} ${hasResponses ? "pointer" : "unselectable-fund-action-row"}`} onClick={() => hasResponses && onFundClick(idx)}  >
                    <td>{content[0]}</td>
                    <td>{content[1]}</td>
                    <td>{content[2]}</td>
                    <td>{content[3]}</td>
                    <td className='workflow-cell-align-right'>{content[4]}</td>
                </tr>
                {selectedFunds && selectedFunds.includes(idx) && fund.responderActions && fund.responderActions.length > 0 && fund.responderActions.map((action, actionIndex) => {
                    const responder = selectedRow.approversAvailable.find(a => a.email === action.responder) 
                    return <tr className='fund-approval-row' key={"approval-" + actionIndex} >
                        <td className='fund-approval-row-cell' colSpan={1} >
                            <div className='inner-cell' style={{paddingLeft: "1rem"}}>
                                <ProfileImage user={{email: action.responderEmail, name: action.responderName}} style={{ marginRight: "0.5rem", transform: "scale(0.75)" }} />
                                {action.responderName}
                            </div>
                        </td>
                        <td className='fund-approval-row-cell' colSpan={4} >
                            <div className='inner-cell'>
                                <InfoBlockStatusToken label={action.status} status={action.status} style={{ marginRight: "0.5rem" }} />
                                {format(parseISO(action.updatedAt), 'HH:mmaaa, dd/MM/yyyy')}
                                {/* {action.comment ? Comment Icon that opens draftPreviewModal} */}
                            </div>
                        </td>
                    </tr>
                })}
                {isLastInList && <tr ><td style={{height: "6rem"}}></td></tr>}
            </>
        )
    })

    if (loading) {
        return (
            <>
                <LoadingIcon centered />
            </>
        )
    }

    if (isComplete) {
        return <p className='all-tasks-completed'>Compliance Actions complete <CheckCircle color='#56D1C3' /></p>
    }

    return (
        <>
            {approversList && approversList.length > 0 && <UserListModal handleClose={() => setApproversList([])} data={approversList} />}
            <FlexWrapper direction="column" style={{ maxWidth: "2000px" }}>
                {selectedRow
                    ? <>
                        <Table
                            colWidths={stepWidths}
                            headers={stepHeaders}
                            dataMap={stepDataMap}
                            minWidth={1000}
                        />
                        <Table
                            // colWidths={stepWidths}
                            dataMap={fundDataMap}
                            minWidth={1000}
                        />
                    </>
                    : <Table
                        colWidths={stepWidths}
                        headers={stepHeaders}
                        dataMap={stepDataMap}
                        minWidth={1000}
                    />}
            </FlexWrapper>
        </>
    )
}

export default WorkflowComplianceActions