import React, { Component } from "react";
import Tooltip from "../popups/Tooltip";
import './ProgressBar.scss';
import NumberFormat from "../Functions/NumberFormat";

/**
 * ProgressBar creates a customizable progress bar component with options for colors and tooltips.
 * 
 * @param {Object} props - The properties for the ProgressBar component.
 * @param {number} props.extent - The maximum value of the progress bar.
 * @param {number} props.value - The current value of the progress bar.
 * @param {number} props.min - The minimum value of the progress bar's variance range.
 * @param {number} props.max - The maximum value of the progress bar's variance range.
 * @param {string} [props.colour1] - The background color of the variance range.
 * @param {string} [props.colour2] - The background color of the progress bar.
 * @param {string} [props.colour3] - The background color of the progress bar container.
 * @param {boolean} [props.noToolTip=false] - If true, tooltips are not displayed.
 * 
 * @returns {JSX.Element} The ProgressBar component.
 */

const ProgressBar = (props) => {

	let { extent, value, min, max, colour1, colour2, colour3, noToolTip } = props;

	let bar = (value / extent * 100) + "%";
	let vMin = (min / extent * 100) + "%";
	let vWidth = ((max - min) / extent * 100) + "%";

	let className = "slider-input-container";
	if (vWidth != "0%" && (value < min || value > max || min > max))
		className += " warning";


	return (
		noToolTip
			? <div className={className} style={{ backgroundColor: colour3 ? colour3 : null, cursor: "default" }}>
				<div className="slider-input" style={{ backgroundColor: colour2 || null, width: `${value / extent * 100}%`, position: "absolute" }} />
				<div className="slider-input-variance" style={{ backgroundColor: colour1 || null, marginLeft: vMin, width: vWidth, position: 'absolute' }}></div>
			</div>
			: <div className={className} style={{ backgroundColor: colour3 ? colour3 : null }}>
				<Tooltip active label={<NumberFormat value={(value / extent) * 100} suffix="%" places={1} />} childStyle={{ marginLeft: bar, zIndex: "41" }}>
					<div className="slider-input" style={{ backgroundColor: colour2 || null, width: `${value / extent * 100}%`, position: "absolute" }} />
					<div className="slider-input-variance" style={{ backgroundColor: colour1 || null, marginLeft: vMin, width: vWidth, position: 'absolute' }}></div>
				</Tooltip>
			</div>
	);

}

export default ProgressBar;