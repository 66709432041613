import React, { useEffect, useState } from 'react';
import PageGridItem from '../Shared/Page/PageGridItem';
import FlexWrapper from '../FlexWrapper';
import Divider from '../../components/Divider';
import { subDays, subHours } from 'date-fns';
import { useParams } from 'react-router-dom';
import { CheckCircle } from 'react-feather';
import LoadingIcon from '../../components/LoadingIcon';
import WorkflowInfoBlock from './WorkflowInfoBlock';
import WorkflowDataSynchronisation from './WorkflowDataSynchronisation'
import WorkflowComplianceActions from './WorkflowComplianceActions'
import WorfklowPublishActions from './WorkflowPublishActions'
import './ActiveWorkflows.scss'
import { useRemote, useStorage } from '../../Utils/Utils';

const TaskOrder = [
	"Data Synchronisation",
	"Compliance Actions",
	"Publish Actions",
]

export const statuses = {
	PENDING: "Pending",
	PENDING_FEEDBACK: "Pending Feedback",
	IN_PROGRESS: "In Progress",
	COMPLETED: "Completed",
	COMPLETE: "Complete",
	INCOMPLETE: "Incomplete",
	ERROR: "Error",
	APPROVED: "Approved"
};


const WorkflowDetails = () => {
	const [workflow, setWorkflow] = useState(null);
	const [viewStep, setViewStep] = useState(0);
	const [loading, setLoading] = useState(true);
	const params = useParams();
	const remote = useRemote();
	const storage = useStorage();

	useEffect(() => {
		storage.getOrFetch("workflows/list")

		// fetch workflow details
		remote.get(`workflows/workflow/${params.workflowId}`).then((res) => {
			setWorkflow(res);
			setLoading(false);
			const taskNames = {
				"Data Synchronisation": 0,
				"Compliance Actions": 1,
				"Publish Actions": 2
			};
			
			if (!res.tasks || res.tasks.length === 0) {
				setViewStep(null);
			} else {
				const orderedTaskNames = ["Data Synchronisation", "Compliance Actions", "Publish Actions"];
				
				for (let taskName of orderedTaskNames) {
					const task = res.tasks.find(task => task.taskName === taskName && task.status && statuses[task.status] !== statuses.COMPLETE);
					if (task) {
						setViewStep(taskNames[taskName]);
						return;
					}
				}
				
				setViewStep(2); // Default to "Publish Actions" if no other tasks are incomplete
			}
		})
	}, [])

	
	if (!workflow) return <><LoadingIcon /></>
	
	const dataSynchronisation = workflow && workflow.tasks && workflow.tasks.find(task => task.taskName === "Data Synchronisation") ? workflow.tasks.find(task => task.taskName === "Data Synchronisation") : null;
	const complianceActions =  workflow && workflow.tasks && workflow.tasks.find(task => task.taskName === "Compliance Actions") ? workflow.tasks.find(task => task.taskName === "Compliance Actions") : null;
	const publishActions =  workflow && workflow.tasks && workflow.tasks.find(task => task.taskName === "Publish Actions") ? workflow.tasks.find(task => task.taskName === "Publish Actions") : null;
	const workflowComplete = dataSynchronisation && complianceActions && publishActions
	&& dataSynchronisation.status === statuses.COMPLETE
	&& complianceActions.status === statuses.COMPLETE
	&& publishActions.status === statuses.COMPLETE;
	
	function getTask(tasks) {
		if (loading || viewStep === null) return null
		if (viewStep === 0) return <WorkflowDataSynchronisation workflowId={workflow.id} taskId={dataSynchronisation.id} isComplete={statuses[dataSynchronisation.status] === statuses.COMPLETE} />
		else if (viewStep === 1) return <WorkflowComplianceActions workflowId={workflow.id} taskId={complianceActions.id} isComplete={statuses[complianceActions.status] === statuses.COMPLETE} />
		else if (viewStep === 2) return <WorfklowPublishActions workflowId={workflow.id} taskId={publishActions.id} isComplete={statuses[publishActions.status] === statuses.COMPLETE} />
		else return <p className='all-tasks-completed'>All processes completed <CheckCircle color='#56D1C3' /></p>
	}
	const dataStatus = dataSynchronisation && dataSynchronisation.status && dataSynchronisation.status.toUpperCase();
	const complianceStatus = complianceActions && complianceActions.status && complianceActions.status.toUpperCase();
	const publishStatus = publishActions && publishActions.status && publishActions.status.toUpperCase();

	if (loading) return <LoadingIcon />

	let workflowTaskProgress = 0
	const workflowTaskTotal = workflow?.tasks?.length || 0
	workflow?.tasks?.forEach((task, idx) => {
		if (statuses[task.status] === statuses.COMPLETE) {
			workflowTaskProgress += 1
		}
	})

	return (
		<>
			<PageGridItem fullWidth >
				<FlexWrapper style={{ height: "120px" }} gap="l" >
					<WorkflowInfoBlock
						header={<span style={{ fontWeight: 600 }}>Summary: {workflow.processName}</span>}
						status={workflowComplete ? statuses.COMPLETED : statuses.IN_PROGRESS}
						completedTasks={workflowTaskProgress}
						totalTasks={workflowTaskTotal}
						actionName="Funds Completed"
						workflowSummary
					/>
				</FlexWrapper >
				<Divider noMargin marginTop="0.5rem" />
			</PageGridItem>
			<PageGridItem fullWidth showScrollbar>
				<FlexWrapper style={{ height: "120px" }} gap="l" >
					{dataSynchronisation &&
						<>
							<FlexWrapper direction="column" style={{ gap: "1rem" }}>
								<WorkflowInfoBlock
									header={dataSynchronisation.taskName}
									status={dataSynchronisation.status}
									completedTasks={dataSynchronisation.progressCurrent}
									totalTasks={dataSynchronisation.progressTotal}
									actionName="Files Imported"
									onClick={() => setViewStep(0)}
									clickable
									active={complianceStatus === statuses.PENDING.toUpperCase()}
									isSelected={viewStep === 0}
								/>
							</FlexWrapper>
							<Divider height={94} vertical />
						</>
					}
					{complianceActions &&
						<>
							<FlexWrapper direction="column" style={{ gap: "1rem" }}>
								<WorkflowInfoBlock
									header={complianceActions.taskName}
									status={complianceActions.status}
									completedTasks={complianceActions.progressCurrent}
									totalTasks={complianceActions.progressTotal}
									actionName="Funds Approved"
									onClick={() => complianceStatus !== statuses.PENDING.toUpperCase() && setViewStep(1)}
									clickable={complianceStatus !== statuses.PENDING.toUpperCase() ? true : false}
									active={complianceStatus !== statuses.PENDING.toUpperCase() && publishStatus === statuses.PENDING.toUpperCase()}
									isSelected={viewStep === 1}
								/>
							</FlexWrapper>
							<Divider height={94} vertical />
						</>
					}
					{publishActions &&
						<>
							<FlexWrapper direction="column" style={{ gap: "1rem" }}>
								<WorkflowInfoBlock
									header={publishActions.taskName}
									status={publishActions.status}
									completedTasks={publishActions.progressCurrent}
									totalTasks={publishActions.progressTotal}
									actionName="Actions Completed"
									onClick={() => publishStatus !== statuses.PENDING.toUpperCase() && setViewStep(2)}
									clickable={ publishStatus !== statuses.PENDING.toUpperCase() ? true : false}
									active={publishStatus !== statuses.PENDING.toUpperCase() && publishStatus !== statuses.COMPLETE.toUpperCase()}
									isSelected={viewStep === 2}
								/>
							</FlexWrapper>
						</>
					}
				</FlexWrapper>
				<Divider spacingTop="m" />
			</PageGridItem>
			<PageGridItem fullWidth>
				{workflow && workflow.tasks && getTask(workflow?.tasks)}
			</PageGridItem>
		</>
	);
}

export default WorkflowDetails;