import React from 'react';
import "./Divider.scss";

const Divider = (props) => {
	const { thick, spacing, spacingTop, spacingBottom, noMargin, dashed, vertical, height, className, dark, marginTop, width } = props;
	let classes = "divider";
	if (className) {
		classes += " " + className;
	}
	if (thick) {
		classes += " divider-thick";
	}
	if (dark) {
		classes += " divider-dark";
	}
	if (spacing) {
		classes += " spacing-" + spacing;
	}
	if (spacingTop) {
		classes += " spacing-top-" + spacingTop;
	}
	if (spacingBottom) {
		classes += " spacing-bottom-" + spacingBottom;
	}
	if (dashed) {
		classes += " divider-dashed";
	}
	if (vertical) {
		classes += " divider-vertical";
	}

	const styleObj = {};
	
	if (noMargin) {
		styleObj.margin = 0;
	}
	if (vertical) {
		styleObj.height = height;
	}
	if (marginTop) {
		styleObj.marginTop = marginTop;
	}
	if (width) {
		styleObj.width = width;
	}

	return (
		<hr className={classes} style={styleObj} />
	)
}

export default Divider;