import React, { useEffect, useState } from 'react';
import Table from '../../components/Table/Table';
import { InfoBlockStatusToken } from './WorkflowInfoBlock'
import { useRemote } from '../../Utils/Utils';
import { CheckCircle } from 'react-feather';

const WorkflowPublishActions = ({workflowId, taskId, isComplete}) => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const remote = useRemote()

	useEffect(() => {
		remote.get(`workflows/workflow/${workflowId}/workflowTask/${taskId}`).then((res) => {
			setData(res.data);
			setLoading(false);
		})
	}, [])

	const colWidths = ['25%', '15%', '60%']
	const headers = ['Action', 'Status', 'Info']

	function dataMap(steps) {
		return steps.map((step, idx) => {
			const { name, status, description } = step
			const isLastInList = idx === steps.length - 1
			return (
				<tr key={'publish-action-step-' + name} className={`${isLastInList ? "mb-xxl" : ""}`}>
					<td>{step.publishActionName}</td>
					<td><InfoBlockStatusToken status={step.publishActionStatus} /></td>
					<td>{step.publishActionInfo ? step.publishActionInfo : "-"}</td>
				</tr>
			)
		})
	}

	if (isComplete) {
        return <p className='all-tasks-completed'>Publish Actions complete <CheckCircle color='#56D1C3' /></p>
    }

	return (
		<Table
			minWidth={1200}
			tableStyle={{ maxWidth: 1400 }}
			colWidths={colWidths}
			headers={headers}
			dataMap={data && dataMap(data)}
			id='worflow-publish-actions-table'
			dataLoading={loading}
		/>
	)
}

export default WorkflowPublishActions