import React from 'react';
import { AlertTriangle } from 'react-feather';
import Page, { PageContentBodyGrid } from './layouts/Shared/Page/Page';
import PageGridItem from './layouts/Shared/Page/PageGridItem';
import './NoAccess.scss';
import Button from './components/Buttons/Button';

const NoAccess = () => {

	const switchUser = () => window.location.href = '/sign-in';

	return (
		<Page noBanner>
			<PageContentBodyGrid id="error-message">
				<PageGridItem colSpan="12">
					<div className="error-message-wrapper">
						<div className="error-header">
							{/* <AlertTriangle /> */}
							<h1>{"Welcome"}</h1>
						</div>
						<div className="error-message">
							<p>{"You currently have no permissions to view this site. Please contact your software support."}</p>
						</div>
						<Button onClick={switchUser}>Switch User</Button>
					</div>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);}

export default NoAccess;