import React, { useState } from 'react';
import Page, { PageContentBodyGrid } from "../Shared/Page/Page";
import WorkflowsList from './WorkflowsList';
import WorkflowDetails from './WorkflowDetails';
import { Switch, Route } from 'react-router';
import { PAGES, ROUTE_PATHS } from '../../InternalApiApp';


const ActiveWorkflowsPage = () => {
	const [selectedWorkflow, setSelectedWorkflow] = useState(null);

	return (
		<Page noBanner id="active-workflows-page" style={{ paddingLeft: "1rem" }}>
			<PageContentBodyGrid rowGap="m">
				<Switch >
					<Route exact path={ROUTE_PATHS[PAGES.ACTIVE_WORKFLOWS] + "/:workflowId"} render={() => <WorkflowDetails selectedWorkflow={selectedWorkflow} setSelectedWorkflow={setSelectedWorkflow} />} />
					<Route exact path={ROUTE_PATHS[PAGES.ACTIVE_WORKFLOWS]} render={() => <WorkflowsList setSelectedWorkflow={setSelectedWorkflow} />} />
				</Switch>
			</PageContentBodyGrid>
		</Page>
	);
}

export default ActiveWorkflowsPage;