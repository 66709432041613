import React, { useState, useEffect } from 'react';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
// import { toastDanger, toastSuccess } from '../../../components/popups/Toast';
import PageGridItem from '../../Shared/Page/PageGridItem';
import useWindowDimensions, { useRemote } from '../../../Utils/Utils';
import { useParams, useHistory } from 'react-router-dom';
import TemplateGeneralDetails from './TemplateGeneralDetails';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner';
import TemplatePublishActions from './TemplatePublishActions';
import TemplateSettingsList from './TemplateSettingsList'
import TemplateOptions from './TemplateOptions';
import MiniSectorsList from '../../Shared/TablesAndLists/MiniSectorsList';
import TemplateCustomPublishActions from './TemplateCustomPublishActions';

const actionTypeLabels = {
	compliance: "Compliance Actions",
	publish: "Publish Actions",
	custom: "Custom Publish Actions",
	data: "Data Actions"
}

const TemplateMgmt = ({ me }) => {
	const remote = useRemote();
	const history = useHistory()
	const { width } = useWindowDimensions();
	const { template: templateId } = useParams()

	const [template, setTemplate] = useState({});
	const [originalTemplate, setOriginalTemplate] = useState({})
	// const [publishActions, setPublishActions] = useState([]);    // we are using the lists above for now
	const [selectedPublishActions, setSelectedPublishActions] = useState([]);
	const [originalSelectedActions, setOriginalSelectedActions] = useState([])
	const [templateDef, setTemplateDef] = useState({});
	const [notFound, setNotFound] = useState(false);
	const [assetType, setAssetType] = useState({ label: 'Sector', value: 'Sector' })
	const [editMode, setEditMode] = useState(false)
	const [actionables, setActionables] = useState(false)
	const actionableCategories = Object.keys(actionables)
	const [changedParams, setChangedParams] = useState({})


	useEffect(() => {
		remote.get("/crm/templates/" + templateId).then((fetchedTemplate) => {
			setOriginalTemplate(fetchedTemplate)
			setTemplate(fetchedTemplate)
		}).catch(e => {
			console.log("Caught e ", e);
			setNotFound(true);
		});
		// remote.get("/crm/publish-steps").then(setPublishActions);    // we are using the lists above for now
		remote.get(`/crm/templates/${templateId}/publish-steps`).then((fetchSelectedActions) => {
			setOriginalSelectedActions(fetchSelectedActions)
			setSelectedPublishActions(fetchSelectedActions)
		});
		remote.get(`/crm/publish-steps`).then((actionables) => {

			let categories = {}
			actionables.forEach(action => {
				if (!categories[action.type]) categories[action.type] = []
				categories[action.type].push(action)
			})
			console.log(categories)
			setActionables(categories)
		});

	}, []);


	useEffect(() => {
		if (template && template.template) {
			try {
				setTemplateDef(JSON.parse(template.template))
			} catch (e) {
				console.log(e)
			}
		}
	}, [template])


	function addActionToTemplate(action) {
		remote.put(`/crm/templates/${templateId}/publish-steps/${action.id}`).then(setSelectedPublishActions);
	}

	function removeActionFromTemplate(action) {
		remote.delete(`/crm/templates/${templateId}/publish-steps/${action.id}`).then(setSelectedPublishActions);
	}

	function goBack() {
		history.push("/admin/templates")
	}

	function saveEdits() {
		setEditMode(false)
		// create real endpoint to modify the template settings
	}

	function cancelEdits() {
		setTemplate(originalTemplate)
		setEditMode(false)
		setSelectedPublishActions(originalSelectedActions)
	}

	if (notFound) return (
		<Page fixedBanner>
			<PageContentBanner divider>
				<ActionButtonsBanner />
			</PageContentBanner>
			<PageContentBodyGrid id="client-details-page" rowGap="xl" gridTemplateRows="1fr" splitScroll>
				<p>Invalid template</p>
			</PageContentBodyGrid>
		</Page>
	)

	return (
		<Page fixedBanner>
			{/* Page banner with back button */}
			<PageContentBanner divider>
				<ActionButtonsBanner
					handleBackOnClick={goBack}
					editMode={editMode}
					edit={{ onClick: () => setEditMode(true) }}
					cancel={{ onClick: () => { cancelEdits() } }}
					save={{ onClick: () => { saveEdits() } }}
				/>
			</PageContentBanner>
			{/* Page content */}
			<PageContentBodyGrid id="client-details-page" rowGap="xl" gridTemplateRows="1fr" splitScroll>
				{/* grid container for the template details section */}
				<PageGridItem container col="1 / span 6">
					<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid>
						{/* General Details */}
						<TemplateGeneralDetails template={templateDef} setTemplate={setTemplateDef} editMode={editMode} />
						<PageGridDivider col="1 / span 6" />
						{/* Settings Lists */}
						{actionableCategories.map((category) => {
							return <TemplateSettingsList
								templateId={templateId}
								templateName={template.name}
								data={actionables && actionables[category] ? actionables[category] : []}
								addActionToTemplate={addActionToTemplate}
								removeAction={removeActionFromTemplate}
								selected={selectedPublishActions}
								setSelectedPublishActions={setSelectedPublishActions}
								templateType={templateDef?.ticker?.templateType || "-"}
								editMode={editMode}
								category={category}
							/>
						})}
					</PageContentBodyGrid>
				</PageGridItem>
				{/* grid container for the sectors list section */}
				<PageGridItem container col="7 / span 6">
					<PageContentBodyGrid showScrollbar rowGap="xl" gridColCount="6" subGrid divider={width < 1200 ? "top" : "left"} paddingBottom={"3rem"}>
						<TemplateOptions template={templateDef} assetType={assetType} setAssetType={setAssetType} />
						{templateDef && templateDef.ticker && templateDef.ticker.options && (templateDef.ticker.type === 'selection' || templateDef.ticker.type === 'scheme') &&
							<>
								<PageGridItem col="1 / span 6">
									<PageGridDivider col="1 / span 6" />
								</PageGridItem>
								<PageGridItem col="1 / span 6">
									<MiniSectorsList data={templateDef.ticker && templateDef.ticker.options} assetType={assetType} stopRedirect />
								</PageGridItem>
							</>
						}
					</PageContentBodyGrid>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default TemplateMgmt;