import { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import logo from './images/3SKYE-logo-black.png'
import LoadingIcon from './components/LoadingIcon';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const CheckAuthorisation = ({ setAuthorised }) => {
    const [loading, setLoading] = useState(true);
    const [failed, setFailed] = useState();

    const location = useLocation();

    const checkAuth = () => {
        const savedProvider = localStorage.getItem("3skye.auth.provider");
        let search = location.search.length > 1 ? location.search.substring(1) : '';
        fetch("/api/crm/me", { credentials: 'same-origin' }).then(resp => {
            if (resp && resp.status && resp.status === 200) {
                setAuthorised(true);
            } else {
                //not authorised - do we know who to authorise against?
                if (savedProvider) {
                    
                    window.location.href = `/api/login?provider=${savedProvider}&path=${location.pathname}&query=${search}`;
                } else {
                    //no saved provider, check if we have a default.
                    fetch("/api/public/configuration").then(resp => {
                        if (resp.defaultLoginProvider) {
                            window.location.href = `/api/login?provider=${resp.defaultLoginProvider}&path=${location.pathname}&query=${search}`;
                        } else {
                            window.location.href = `/sign-in`;
                        }
                    })
                }
            }

        }, (error) => {
            console.log("Promise rejected with ", error)
        })
    }

    useEffect(() => {
        checkAuth();
    }, [])

    return (
        <div
            style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '20px', backgroundColor: '#fff' }}
        >
            <img src={logo} alt="3skye logo" style={{ maxHeight: "150px", maxWidth: "300px" }} />
            <h3>
                Checking your Authorisation.
            </h3>
            <LoadingIcon />
        </div>
    )
}

export default CheckAuthorisation;