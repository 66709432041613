import React from 'react';
import "./PulseDot.scss";

const PulseDot = () => {
	return (
		<div class="pulse-dot-container">
			<div class="pulse-dot-dot"></div>
			<div class="pulse-dot-pulse"></div>
			<div class="big-pulse-dot-pulse"></div>
		</div>
	);
}

export default PulseDot;